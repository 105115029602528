export const cryptoCurrencies: ICryptoList = {
  AAVE: {
    fullName: "Aave",
    currentPrice: null,
    ticker: "aave",
    marketCap: null,
    change: null,
  },
  ADA: {
    fullName: "Cardano",
    currentPrice: "",
    ticker: "ada",
    marketCap: null,
    change: null,
  },
  ALGO: {
    fullName: "Algorand",
    currentPrice: "",
    ticker: "algo",
    marketCap: null,
    change: null,
  },
  BTC: {
    fullName: "Bitcoin",
    currentPrice: "",
    ticker: "btc",
    marketCap: null,
    change: null,
  },
  BCH: {
    fullName: "Bitcoin Cash",
    currentPrice: null,
    ticker: "bch",
    marketCap: null,
    change: null,
  },
  DAI: {
    fullName: "Dai",
    currentPrice: null,
    ticker: "dai",
    marketCap: null,
    change: null,
  },
  DOGE: {
    fullName: "Doge",
    currentPrice: null,
    ticker: "doge",
    marketCap: null,
    change: null,
  },
  DOT: {
    fullName: "Polkadot",
    currentPrice: null,
    ticker: "dot",
    marketCap: null,
    change: null,
  },
  ETH: {
    fullName: "Ethereum",
    currentPrice: null,
    ticker: "eth",
    marketCap: null,
    change: null,
  },
  LINK: {
    fullName: "Chainlink",
    currentPrice: null,
    ticker: "link",
    marketCap: null,
    change: null,
  },
  LTC: {
    fullName: "Litecoin",
    currentPrice: null,
    ticker: "ltc",
    marketCap: null,
    change: null,
  },
  MATIC: {
    fullName: "Polygon",
    currentPrice: null,
    ticker: "matic",
    marketCap: null,
    change: null,
  },
  SHIB: {
    fullName: "Shibu",
    currentPrice: null,
    ticker: "shib",
    marketCap: null,
    change: null,
  },
  SOL: {
    fullName: "Solana",
    currentPrice: null,
    ticker: "sol",
    marketCap: null,
    change: null,
  },
  UNI: {
    fullName: "Uniswap",
    currentPrice: null,
    ticker: "uni",
    marketCap: null,
    change: null,
  },
  XLM: {
    fullName: "Stellar Lumens",
    currentPrice: null,
    ticker: "xlm",
    marketCap: null,
    change: null,
  },
  XRP: {
    fullName: "XRP",
    currentPrice: null,
    ticker: "xrp",
    marketCap: null,
    change: null,
  },
  XTZ: {
    fullName: "Tezos",
    currentPrice: null,
    ticker: "xtz",
    marketCap: null,
    change: null,
  },
};
