export const defaultCard: ICard = {
  id: Date.now().toString(),
  cryptoName: "",
  cryptoTicker: "",
  cryptoPrice: "",
  purchasePrice: null,
  purchaseDate: null,
  sellPrice: null,
  sellDate: null,
  purchaseSource: "",
  sellSource: "",
  investment: null,
  investmentType: null,
  coins: null,
};
